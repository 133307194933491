@tailwind base;
@tailwind components;
@tailwind utilities;

.basic-section-header {
  @apply flex flex-row justify-between items-center;
}

.basic-nav {
  @apply flex cursor-pointer flex-row gap-1 lg:gap-[10px] justify-center items-center text-primary;
}

.basiccatalog-content-ctn {
  @apply bg-[#F9F9F9] lg:w-[33%] rounded-lg;
}
