

.wrapper {
    display: flex;
    width: 100%;
    max-width: 2000px;
    margin-left: auto;
      margin-right: auto;
    justify-content: center;
    align-items: flex-start;
}
@media (max-width: 1023px) {
  .wrapper {
      flex-direction: column;
  }
}
.img-wrapper {
    width: 50%;
  height: 100%;
}
@media (max-width: 1024px) {
  .img-wrapper {
      display: none;
  }
}
.full-size {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .right-content-wrapper {
    width: 50%;
    padding: 7%;
    color: black;
  }
  
  .right-content-wrapper > * + * {
    margin-top: 2rem; /* 8 x 0.25rem (1rem = 16px) = 2rem */
  }
  
  @media (max-width: 1024px) {
    .right-content-wrapper {
        width: 100%;
    }
  }


.text-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .first-text {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
  }

  .small-text {
    font-size: 0.875rem; /* text-sm, equivalent to 14px */
    color: #5F738C; 
    padding-top: 8px;/* text color */
  }

  .email-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  
  
  @media (max-width: 1023px) {
    .email-wrapper {
        width: 100%;
    }
  }


.email-input {
    display: flex;
    width: 100%;
    height: 48px;
    border-radius: 0.375rem; /* 6px, which is the equivalent of 'rounded-md' */
    border: 1px solid var(--input-border-color); /* Assuming 'border-input' is a CSS variable */
    background-color: #F3F5F6;
    padding: 0.5rem 0.75rem; /* px-3 py-2 translates to padding-top/bottom: 0.5rem (8px), padding-left/right: 0.75rem (12px) */
    font-size: 0.875rem; /* 14px, equivalent to 'text-sm' */
    outline-offset: 0.375rem; /* 6px, which is the equivalent of 'ring-offset-[#144618]' */
  }
  
  .email-input::file-selector-button {
    border: 0;
    font-size: 0.875rem; /* 14px, equivalent to 'file:text-sm' */
    font-weight: 500; /* Equivalent to 'file:font-medium' */
  }
  
  .email-input:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px #144618; /* Equivalent to 'focus-visible:ring-1 focus-visible:ring-[#144618]' */
  }
  
  .email-input:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .change-btn {
    color: #087867;
    font-size: 0.875rem; /* 14px */
    position: absolute;
    right: -15px;
    width: 100px;
    border: none;
    outline: none;
    background-color: transparent;
  }
  .otp-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.75rem; /* 12px */
  }
  .otp-wrapper {
    width: 65%;
    color: #4D5761;
    font-size: 0.875rem; /* 14px */
  }
  .resend {
    color: #04876C;
  }
  .form-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem; /* 20px */
  }
  .proceed-btn {
    width: 100%;
    height: 48px;
    color: white;
    font-weight: 600; /* semibold */
    background-color: #144618;
    font-size: 0.875rem; /* 14px */
    border-radius: 8px;
    
  }
  
  @media (max-width: 1024px) {
    .proceed-btn {
        width: 100%;
    }
  }


  .login-text {
    font-size: 0.875rem; /* 14px */
    color: #5F738C;
    width: 100%;
    text-align: center;
    margin-top: 4%;
  }
  .login-link {
    text-decoration: underline;
    color: #144618; /* text color */
    font-weight: 700; /* bold text */
  }