@tailwind base;
@tailwind components;
@tailwind utilities;

.newUserCtn {
  @apply p-4 lg:p-[30px] bg-white;
}

.newuserform {
  @apply lg:mt-[45px] flex flex-col gap-2 lg:gap-10;
}
