@tailwind base;
@tailwind components;
@tailwind utilities;

.basic-sales-content {
  @apply w-full lg:h-[400px] rounded-lg bg-darkgreen  px-5 py-[40px] lg:px-0 lg:py-0 gap-[30px];
}

.sales-content-ctn {
  @apply bg-[#F9F9F9] lg:w-[33%] rounded-lg;
}

.sales-nav {
  @apply flex flex-row gap-1 items-center;
}
