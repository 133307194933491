@tailwind base;
@tailwind components;
@tailwind utilities;

.basic-map-weather {
  @apply flex flex-1 flex-col lg:flex-row gap-5 lg:gap-10  px-[20px] lg:px-[40px];
}

.basic-map-section {
  @apply flex flex-[3] flex-col gap-3 lg:gap-[25px];
}

.basic-weather-section {
  @apply hidden lg:flex flex-col flex-[1] gap-[25px];
}

.basic-map {
  @apply w-full h-[250px] lg:p-0 py-[24px] overflow-hidden  lg:h-[400px] lg:bg-darkgreen flex flex-col justify-center items-center gap-[15px] lg:gap-[30px];
}

.icon-wrapper {
  @apply w-[60px] bg-primary h-[60px] rounded-full items-center justify-center flex;
}

.weather {
  @apply p-[30px] rounded-lg overflow-hidden w-full flex flex-col justify-between h-[400px] bg-cover bg-center;
}

.weather-icons {
  @apply flex gap-[10px] items-center;
}


