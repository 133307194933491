@tailwind base;
@tailwind components;
@tailwind utilities;

.section-header {
  @apply flex flex-row justify-between items-center;
}

.tips-nav {
  @apply lg:flex hidden cursor-pointer flex-row gap-[10px] justify-center items-center text-primary;
}

.tips-ctn {
  @apply lg:w-[33%] w-[260px] justify-start items-start rounded-lg;
}
