@tailwind base;
@tailwind components;
@tailwind utilities;

.inventory-container {
  @apply flex flex-col gap-3 lg:gap-[30px] lg:mr-[122px];
}

.inventory-content {
  @apply flex gap-2 lg:gap-[15px];
}
