@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-spin-dot-item {
  background-color: #144618 !important;
}
.ts {
    @apply text-xs lg:text-sm;
}
.basiccatalog-content-ctn {
    @apply bg-[#F9F9F9] lg:w-[33%] rounded-lg;
  }
  .filter {
    @apply w-full flex lg:flex-row flex-col-reverse justify-between items-start lg:items-center  gap-2;
  }
  .upgrade {
    @apply text-darkGreen ts flex justify-start w-full items-center mt-2 font-semibold;
  }
  .catalogcard {
    @apply w-full lg:w-[32%]  bg-[#f9f9f9] rounded-md p-3 flex  flex-col;
  }