@tailwind base;
@tailwind components;
@tailwind utilities;


ul {
    list-style: none; /* Remove default bullets */
    counter-reset: list-counter; /* Initialize counter */
  }
  
  ul li {
    counter-increment: list-counter; /* Increment counter */
    margin-bottom: 5px;
  }
  
  ul li::before {
    content: counter(list-counter) ". "; /* Add counter before each list item */
    font-weight: bold;
  }
.smallcard {
    @apply rounded-lg w-full h-full object-cover cursor-pointer;
}