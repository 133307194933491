@tailwind base;
@tailwind components;
@tailwind utilities;

.section-header {
  @apply flex flex-row justify-between items-center;
}

.news-nav {
  @apply hidden lg:flex cursor-pointer flex-row gap-[10px] justify-center items-center text-primary;
}

.basic-news-content-ctn {
  @apply bg-[#F9F9F9] lg:w-[25%] rounded-lg   gap-2 lg:gap-5;
}
