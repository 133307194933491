@tailwind base;
@tailwind components;
@tailwind utilities;

.bell-style {
  @apply bg-[#ADEE68] text-black rounded-full p-1 w-[30px] h-[30px] lg:w-[38px] lg:h-[38px];
}
.pro-topnav-container {
  @apply w-full bg-white flex justify-between p-[25px] items-center lg:py-3 lg:border-b lg:px-[2%];
}
.pronav-content-style {
  @apply w-full hidden lg:flex justify-between lg:justify-end items-end gap-2;
}
