@tailwind base;
@tailwind components;
@tailwind utilities;

input {
  @apply border border-[#144618] p-1 lg:py-3 lg:px-2 rounded-lg w-full lg:w-[407px] font-[400];
}

.hero {
  @apply bg-primary px-5 py-3 lg:px-[100px] lg:py-[64px] flex flex-col;
}

.content {
  @apply flex flex-col-reverse gap-5 lg:flex-row justify-between;
}

.left {
  @apply flex flex-[0.5] flex-col gap-[40px];
}

.hero_text {
  @apply font-[700] text-primary text-[24px] lg:text-[72px] text-center lg:text-start  leading-[32px] lg:leading-[85px];
}

.hero_subtext {
  @apply font-[600] text-primary text-center lg:text-start text-[12px] lg:text-[16px] lg:leading-[21.6px];
}

.first {
  @apply bg-[#144618]  lg:py-[72px] lg:px-[156px] gap-10 lg:gap-20 flex flex-col justify-center items-center text-white font-[600] text-center;
}

.img_text {
  @apply flex items-center gap-40;
}

.img_text_content {
  @apply flex items-center flex-col gap-5;
}

.content_second {
  @apply flex flex-col lg:flex-row w-full gap-10 lg:gap-40;
}

.left_text {
  @apply lg:flex-[0.5] flex flex-col gap-5;
}

.right_img {
  @apply lg:flex-[0.5] h-[250px]  lg:h-[498px] rounded-[20px] bg-[#D9D9D9];
}
