@tailwind base;
@tailwind components;
@tailwind utilities;

.enterprise_link-wrapper {
  @apply w-full flex flex-col mt-[5%] pl-[10px];
}

.nav-link {
  @apply w-[180px] px-3;
}

.enterprise-link-container {
  @apply w-full flex gap-6 capitalize text-gray-600 justify-start  text-sm  h-[45px]  items-center;
}
