.menuz {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 10;
  width: 150px;
}

.sort {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.sort-hover {
  padding: 8px 16px;
  cursor: pointer;
  display: block;
}

.sort-hover:hover {
  background-color: #f0f0f0;
}
