@tailwind base;
@tailwind components;
@tailwind utilities;

.admin-link-container {
  @apply w-full  flex gap-6 capitalize text-gray-600 ml-[60px]  text-sm  h-[45px]  items-center;
}
.link-wrapper {
  @apply w-full flex-col mt-[5%];
}
