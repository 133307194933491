table {
	border-collapse: collapse;
     width: 35vw;
}
table td {
	padding: 15px;
   
}
table thead td {
	background-color: #ebeced;
	color: black;
	font-weight: bold;
	font-size: 13px;
	border: 1px solid #ebeced;
}
table tbody td {
	color: black;
	border: 1px solid #ebeced;
}
table tbody tr {
	background-color: #f9fafb;
}
table tbody tr:nth-child(odd) {
	background-color: #ffffff;
}
.inputborder {
  border: none;
  outline: none;
}
