@tailwind base;
@tailwind components;
@tailwind utilities;

.newProductwrapper {
  @apply p-[30px];
}
.productform {
  @apply mt-[45px] flex flex-col gap-10;
}
