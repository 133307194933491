@tailwind base;
@tailwind components;
@tailwind utilities;

.about_main {
  @apply bg-primary;
}

.about {
  @apply px-0;
}

.header {
  @apply py-3 px-5 lg:py-[28px] justify-between flex flex-row items-center text-white font-[400];
}

.header_main {
  @apply bg-[#144618] flex flex-col gap-10;
}

.hero_img {
  @apply lg:h-[500px] h-[400px] bg-cover bg-center;
}

.hero_content {
  @apply  flex flex-col items-center justify-center gap-[20px];
}

.hero_text {
  @apply font-[800] text-[64px] text-white leading-[73px] text-center;
}
.hero_subtext {
  @apply font-[500] text-[16px] text-white leading-[22px] text-center;
}

.find_button {
  @apply rounded-[6px] cursor-pointer lg:bg-white bg-[#ADEE68]  w-full lg:w-[336px] flex items-center justify-center  text-[16px] text-primary font-[700] px-2 py-1 lg:py-[10px] lg:px-6 self-center;
}

.first {
  @apply py-[100px] text-white text-center;
}

.top {
  @apply flex flex-col;
}

.bottom {
  @apply flex flex-col-reverse lg:flex-row justify-center items-center gap-4  lg:gap-40 py-16;
}

.second {
  @apply bg-[#144618] lg:py-[72px] px-5 py-5 lg:px-[156px] gap-5 lg:gap-20 flex flex-col justify-center items-center text-white font-[600] text-center;
}

/* .img_text {
  @apply;
} */

.img_text_content {
  @apply flex items-center flex-col gap-1 lg:gap-5;
}

.goals {
  @apply flex flex-col lg:py-[72px] px-5 py-6 lg:px-[80px] gap-3 lg:gap-10;
}

.top_image {
  @apply flex gap-3 lg:gap-10 w-full items-center;
}

.bottom_image {
  @apply flex items-center h-[100px] lg:h-[405px] w-full gap-3 lg:gap-10;
}

.goals_content {
  @apply flex flex-col items-start text-start font-[600] text-[20px] lg:text-[28px] leading-[30px] lg:leading-[41px] text-primary;
}

.goals_button {
  @apply bg-primary w-full  font-[600] py-[10px] lg:py-[20px] px-[17px] lg:px-[35px] leading-none rounded-lg text-primary;
}

.tgaims-customers {
  @apply flex flex-col lg:py-[72px] px-5 py-5 lg:px-[80px] lg:gap-[60px] w-full;
}

.fourth_content {
  @apply flex flex-col items-center gap-5 lg:gap-20 mt-[100px];
}

.fifth {
  @apply flex flex-col lg:flex-row items-center justify-center gap-10 lg:gap-20 lg:py-[72px] px-5 py-10 lg:px-[100px];
}

.faqs {
  @apply flex flex-col lg:flex-row gap-3 lg:gap-10 text-primary justify-between;
}

.accordion {
  @apply flex flex-row justify-between items-center gap-5;
}

.sixth {
  @apply items-center justify-center flex flex-col py-[18px] lg:py-[72px] px-5 gap-20 text-center text-primary;
}

input {
  @apply border border-[#144618] py-4 px-3 rounded-lg lg:w-[407px];
}
