@tailwind base;
@tailwind components;
@tailwind utilities;

.sidebar-wrapper {
  @apply w-[18%] bg-[#232221] lg:flex flex-col justify-between items-center h-screen hidden py-3;
}
.top-content-wrapper {
  @apply w-full flex flex-col justify-center items-start;
}
.logo-cover {
  @apply w-full flex justify-start items-center pl-10;
}
.logout-button-style {
  @apply text-white gap-2 justify-start  w-[80%] mx-auto rounded-md p-3 ml-10;
}
.topbar-wrapper {
  @apply w-full flex flex-col gap-1  lg:pl-[18%] pl-0;
}
