@tailwind base;
@tailwind components;
@tailwind utilities;

.newUserCtn {
  @apply p-[15px] lg:p-[30px] bg-white;
}

.newuserform {
  @apply mt-[20px] lg:mt-[45px] flex flex-col gap-5 lg:gap-10;
}
