@tailwind base;
@tailwind components;
@tailwind utilities;

.section-header {
  @apply flex flex-row justify-between items-center;
}

.basic-nav {
  @apply flex cursor-pointer flex-row  gap-1 lg:gap-[10px] justify-center items-center text-primary;
}
